.hero-section {
    margin: 30px 0 80px 0;
}

.hero-col-1 {
    padding: 0 !important;
    width: 100%;
    height: 100%;
    align-self: center;
}

.hero-col-1 .hero-col-1-box {
    justify-content: center;
    flex-direction: column;
}

.hero-col-1 h1 {
    font-size: 2.5rem;
    padding: 10px 0;
}

.hero-col-1 p {
    padding: 0;
    margin: 0;
    /* font-size: 1.5vh; */
}

.hero-work {
    background: #FFFFFF;
    background: -webkit-linear-gradient(to bottom, #FFFFFF 0%, #8000FF 100%);
    background: -moz-linear-gradient(to bottom, #FFFFFF 0%, #8000FF 100%);
    background: linear-gradient(to bottom, #FFFFFF 0%, #8000FF 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.hero-buttons {
    width: 100%;
    margin: 20px 0;
    display: flex;
}

.hero-buttons-mobile {
    display: none;
}

.hero-buttons a {
    text-decoration: none;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-buttons a:nth-child(1) {
    margin: 0 5px 0 0;
}

.hero-buttons a:nth-child(2) {
    margin: 0 0 0 5px;
}

.hero-book-a-meeting {
    cursor: pointer;
    text-align: center;
    background-color: white;
    color: black !important;
    border-radius: 100px;
    border: 2px solid white;
}

.hero-reviews {
    cursor: pointer;
    text-align: center;
    border-radius: 100px;
    border: 2px solid white;
    color: white !important;
}

/* HERO COLUMN 2 */

.hero-col-2 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-col-2 img {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* TABLETS */
@media only screen and (min-width: 576px) and (max-width: 992px) {
    .hero-buttons {
        padding: 0 100px;
    }

    .hero-buttons a:nth-child(1) {
        margin: 0 20px 0 0;
    }
    
    .hero-buttons a:nth-child(2) {
        margin: 0 0 0 20px;
    }
}

/* MOBILE PHONES */
@media only screen and (max-width: 992px) {
    .hero-buttons-pc {
        display: none !important;
    }

    .hero-buttons-mobile {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

/* HERO BUTTON FOR SMALLER SCREENS */
@media only screen and (max-width: 361px) {
    .hero-book-a-meeting,
    .hero-reviews {
        font-size: 4.2vw;
    }
} 

/* HERO BUTTON FOR SMALLER TABLETS */
@media only screen and (min-width: 576px) and (max-width: 767px) {
    .hero-book-a-meeting,
    .hero-reviews {
        font-size: 1.73vw;
    }
}