.stats-section {
    text-align: start;
    margin: 80px 0;
}

.stats-col {
    display: grid;
    place-items: center;
    padding: 30px;
    text-align: center;
}

.stats-col:nth-child(1) {
    text-align: start;
}

.stats-col:nth-child(1) {
    border-top: 0.25px solid transparent;
    border-right: 0.25px solid white;
    border-bottom: 0.25px solid transparent;
    border-left: 0.25px solid transparent;
}

.stats-col:nth-child(2) {
    border-top: 0.25px solid transparent;
    border-right: 0.25px solid white;
    border-bottom: 0.25px solid transparent;
    border-left: 0.25px solid white;
}

.stats-col:nth-child(3) {
    border-top: 0.25px solid transparent;
    border-right: 0.25px solid transparent;
    border-bottom: 0.25px solid transparent;
    border-left: 0.25px solid white;
}

.stats-we-keep-count {
    background: #8F00FF;
    background: -webkit-linear-gradient(to right, #8F00FF 0%, #FF00D6 100%);
    background: -moz-linear-gradient(to right, #8F00FF 0%, #FF00D6 100%);
    background: linear-gradient(to right, #8F00FF 0%, #FF00D6 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media only screen and (max-width: 576px) {

    .stats-col:nth-child(1),
    .stats-col:nth-child(2),
    .stats-col:nth-child(3) {
        border: none;
    }

    .stats-col:nth-child(1),
    .stats-col:nth-child(2) {
        position: relative;
        border: none;
    }

    .stats-col:nth-child(1)::after {
        content: "";
        position: absolute;
        width: 35vw;
        height: 20px;
        margin: 0 50px 0 0;
        right: 0;
        bottom: 0;
        border-bottom: 1px solid white;
    }

    .stats-col:nth-child(2)::before {
        content: "";
        position: absolute;
        width: 35vw;
        height: 20px;
        margin: 0 0 0 50px;
        left: 0;
        bottom: 0;
        border-bottom: 1px solid white;
    }
}