.services-section {
  margin: 80px 0;
  padding: 0 !important;
  color: white !important;
}

.services-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: auto;
}

.services-heading strong {
  padding: 25px 100px;
  padding-top: 0 !important;
  border-bottom: 1px solid white;
}

.services-heading strong span {
  background: #8f00ff;
  background: -webkit-linear-gradient(to right, #8f00ff 0%, #ff00d6 100%);
  background: -moz-linear-gradient(to right, #8f00ff 0%, #ff00d6 100%);
  background: linear-gradient(to right, #8f00ff 0%, #ff00d6 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.services-pc {
  margin: 50px 0;
}

.services-pc .row {
  gap: 40px;
  justify-content: center;
  align-items: center;
}

.services-pc .row:nth-child(1) {
  margin: 0 0 40px 0;
}

.services-pc .row:nth-child(2) {
  margin: 40px 0 0 0;
}

.services-col div {
  text-align: center;
  font-weight: bold;
  font-size: 2rem;
}

.services-col {
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1 / 1;
  width: 30% !important;
  max-height: 100%;
  border-radius: 10px;
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(90deg,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(90deg,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(90deg,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0.1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#ffffff", GradientType=1);
}

.services-tab-mobile {
  display: none;
  margin: 40px auto;
}

.carousel {
  height: 15rem !important;
  margin: 0 !important;
  padding: 0 !important;
}

.carousel-inner {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0 !important;

  width: 100% !important;
  height: 100% !important;

  border-radius: 10px;
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(90deg,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0.1) 100%);
  background: -webkit-linear-gradient(90deg,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(90deg,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0.1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#ffffff", GradientType=1);
}

.carousel-item div {
  padding: 100px;
  margin-left: -100% !important;
  font-weight: bold;
  font-size: 20px;
}

.carousel-indicators button {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
  border: none !important;
  margin: 0 5px !important;
}

.carousel-indicators .active {
  background-color: #8f00ff !important;
}

@media only screen and (max-width: 992px) {
  .services-pc {
    display: none;
    color: white !important;
  }

  .services-tab-mobile {
    display: grid;
    place-items: center;
    color: white !important;
  }

  .services-heading strong {
    padding: 25px 0;
    border-bottom: 1px solid white;
    color: white !important;
  }

  .carousel {
    width: 20rem !important;
  }

}

@media only screen and (max-width: 410px) {
  .carousel {
    width: 15rem !important;
  }
}