@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700;800&display=swap");

* {
  color: white;
  font-family: "Montserrat", sans-serif;
}

section {
  margin: 100px 0;
}

h1 {
  margin: 0;
  padding: 0;
  /* font-size: 3rem; */
  font-weight: bold;
  color: white !important;
}

h1 strong {
  margin: 0;
  padding: 0;
  /* font-size: 3rem; */
  font-weight: bold;
  color: white !important;
}

a {
  text-decoration: none;
}

/* new */

.logo-route-link {
  text-decoration: none;
}
.error-text {
  display: flex !important;
  align-items: center !important;
  text-align: center;
  justify-content: center !important;
  height: 50vw !important;
  flex-direction: column;
}
