.hear-our-clients-section .hear-our-clients-reviews .row {
    margin: 80px auto 0;
}

.hear-our-clients-section .hear-our-clients-reviews .row div {
    margin-bottom: 50px;
}

.hear-our-clients-section .hear-our-clients-reviews .row div img {
    width: 100%;
}

@media only screen and (max-width: 768px) {
    .hear-our-clients-section .hear-our-clients-reviews .row div img {
        margin-bottom: 50px;
    }
}

@media only screen and (max-width: 1199px) and (min-width: 768px) {
    .hear-our-clients-section .hear-our-clients-reviews .row div img {
        width: 107%;
        margin-bottom: 10px;


    }
}

@media  only screen and (max-width: 1399px){
    .hear-our-clients-section .hear-our-clients-reviews .row div {
        margin-bottom: 3px;
    }
}