.FAQ-section .row:nth-child(1) {
    text-align: center;

}

.FAQ-section .row:nth-child(1) h1 {
    font-size: 3rem;
    font-weight: bold;
    margin: 0;
    padding: 0;
    

}

.FAQ-section .row:nth-child(1) h1 span {
    background: #8F00FF;
    background: -webkit-linear-gradient(to right, #8F00FF 0%, #FF00D6 100%);
    background: -moz-linear-gradient(to right, #8F00FF 0%, #FF00D6 100%);
    background: linear-gradient(to right, #8F00FF 0%, #FF00D6 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    
}

.FAQ-section .row:nth-child(2) {
    margin: 50px 25vw;
    height: 1px !important;
    background-color: white;
}

.FAQ-section .row:nth-child(3) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0;
    color: rgb(255, 255, 255) !important;
}

.FAQ-section .row:nth-child(3) .accordion .accordion-item {
    border-color: rgba(255, 255, 255, 0.2) !important;
}

.FAQ-section .row:nth-child(3) .accordion .accordion-item,
.FAQ-section .row:nth-child(3) .accordion .accordion-item .accordion-header .accordion-button {
    background-color: transparent !important;
    /* color: transparent !important; */
    
    color: white !important;
    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(90deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.1) 100%);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.01) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#ffffff", GradientType=1);

}

