.about-section {
  margin: 100px 0;
}

.about-section-pc .row {
  display: flex;
  justify-content: space-between;
}

.about-section-pc .row .about-pc-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-section-pc .row .about-pc-col:nth-child(1) {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 0;
  width: 20%;
}

.about-section-pc .row .about-pc-col:nth-child(2) {
  width: 1px !important;
  background-color: white;
  padding: 0;
}

.about-section-pc .row .about-pc-col:nth-child(3) p span {
  color: #612fab !important;
}

.about-section-pc .row .about-pc-col:nth-child(3) p {
  font-size: 1.5rem;
  padding: 60px 30px;
  background-color: #d9d9d915;
  border-radius: 20px;
}

.about-section-pc .row .about-pc-col:nth-child(3) {
  margin: 0;
  padding: 0;
  width: 60%;
}

/* For Tablets and Mobiles */
@media only screen and (max-width: 768px) {
  .about-section .about-section-pc .row {
    flex-direction: column !important;
    align-items: center !important;
  }
  .about-section .about-section-pc .row .about-pc-col:nth-child(1) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  .about-section .about-section-pc .row .about-pc-col:nth-child(2) {
    height: 1px !important;
    width: 50vw !important;
    margin: 25px 0;
  }
  .about-section .about-section-pc .row .about-pc-col:nth-child(3) {
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  .about-section-pc .row .about-pc-col:nth-child(3) p {
    padding: 30px;
  }
}

/* p {
  color: white !important;
} */

/* How we work css */

.ArrowIcon {
  margin: 1em;
}

