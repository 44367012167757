.footer-container {
  background-color: rgb(103, 0, 255, 0.17);
  list-style-type: none;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.footer-container footer {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 2fr 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.footer-container footer .div1 { grid-area: 1 / 1 / 2 / 2; }

.footer-container footer .div2 { grid-area: 1 / 2 / 2 / 3; }

.footer-container footer .div3 { grid-area: 2 / 1 / 3 / 2; }

.footer-container footer .div4 { grid-area: 2 / 2 / 3 / 3; }

.footer-container footer .div1, 
.footer-container footer .div2,
.footer-container footer .div3,
.footer-container footer .div4 {
  display: flex;
}

.footer-container footer .div1 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
}

.footer-container footer .div1 a li:hover,
.footer-container footer .div2 a img:hover,
.footer-container footer .div4 a li:hover{
  opacity: 0.7;
}

.footer-container footer .div2 {
  justify-content: space-between;
}

.footer-container footer .div2 a img {
  width: 50px;
}

.footer-container footer .div3 {
  justify-content: flex-start;
  align-items: flex-end;
}

.footer-container footer .div3 li {
  color: rgba(255, 255, 255, 0.5) !important;
}

.footer-container footer .div4 {
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.footer-container footer .div4 a li {
  color: rgba(255, 255, 255, 0.5) !important;
}

@media only screen and (max-width: 768px) {
  .footer-container footer .div2 {
    flex-direction: column;
    gap: 10px;
    align-items: flex-end;
  }

  .footer-container footer .div2 a img {
    width: 45px;
  }

  .footer-container footer .div3 li {
    font-size: small;
  }

  .footer-container footer .div4 a li {
    font-size: small;
  }
}

@media only screen and (max-width: 401px) {

  .footer-container footer .div3 li {
    font-size: x-small;
  }

  .footer-container footer .div4 a li {
    font-size: x-small;
  }

}

/* .footer-socials {
  float: right;
  display: flex;
  justify-content: space-around;
  width: 20%;
  margin-top: 1em;
}

.footer-container .footer-navs li {
  list-style: none;
}

.footer-conditions {
  float: right;
} */