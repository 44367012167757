.tech-skill-section {
  margin: 80px 0;
}

.tech-skill-heading {
  text-align: center;
  position: relative;
  border: none;
  padding: 40px 0;
  margin: 40px 0;

  /* background: #8f00ff;
  background: -webkit-linear-gradient(to right, #8f00ff 0%, #ff00d6 100%);
  background: -moz-linear-gradient(to right, #8f00ff 0%, #ff00d6 100%);
  background: linear-gradient(to right, #8f00ff 0%, #ff00d6 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent; */
}

.tech-skill-heading::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 50vw;
  height: 1px;
  background-color: white;
}

.tech-skill-heading strong span {
  background: #8f00ff;
  background: -webkit-linear-gradient(to right, #8f00ff 0%, #ff00d6 100%);
  background: -moz-linear-gradient(to right, #8f00ff 0%, #ff00d6 100%);
  background: linear-gradient(to right, #8f00ff 0%, #ff00d6 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tech-skill-col {
  aspect-ratio: 1 / 1;
  width: 8vw;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tech-skill-col img {
  width: 8vw;
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;
}

.tech-skill-col img:hover {
  opacity: 1;
}

@media only screen and (max-width: 576px) {
  .tech-skill-section .row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0 !important;
  }

  .tech-skill-col {
    width: 15vw !important;
  }

  .tech-skill-col img {
    width: 15vw !important;
  }
}

/* For touch screen devices the logos are not hoverable hence opacity: 1 */
@media (pointer: coarse) {
  .tech-skill-col img {
    opacity: 1;
  }
}
