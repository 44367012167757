.navbar-section {
  margin: 30px 0;
}

.navbar-section .row .navbar-item:nth-child(1) {
  display: flex;
  justify-content: start;
  align-items: center;
}

.navbar-section .row h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  padding: 0 !important;
  margin: 0 !important;
}

.navbar-section .row .navbar-item:nth-child(2) {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.navbar-section .row .navbar-item:nth-child(3) {
  display: flex;
  justify-content: end;
  align-items: center;
}

.navbar-section .row .navbar-item:nth-child(2) ul {
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
  padding: 0 !important;
}

.navbar-section .row .navbar-item:nth-child(2) ul li {
  margin: 0 30px;
}

.navbar-section .row .navbar-item:nth-child(2) ul li a {
  text-decoration: none;
  color: white;
}

.navbar-section .row .navbar-item:nth-child(2) ul li a:hover {
  color: rgb(255, 0, 214) !important;
}

.navbar-section .row .navbar-item:nth-child(3) button {
  background-color: rgb(97, 47, 171, 0.3);
  padding: 10px 20px;
  border-radius: 100px;
  border: 0;
  margin: 0 0 0 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: box-shadow 0.1s;
}

.navbar-section .row .navbar-item:nth-child(3) button:hover {
  box-shadow: 0px 0px 37px rgb(255, 0, 214, 0.53);
  cursor: pointer;
}

/* Responsive Navbar */

.navbar-mobile {
  display: none !important;
  justify-content: center;
  align-items: center;
}

.navbar-mobile-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-section .navbar-mobile .navbar-mobile-item:nth-child(1) {
  justify-content: start;
}

.navbar-section .navbar-mobile .navbar-mobile-item:nth-child(3) {
  justify-content: end;
}

.navbar-mobile-active {
  /* When the mobile navbar opens this CSS will be applied */
}

/* Responsive Navbar */

.navbar-section
  .navbar-mobile
  .navbar-mobile-item:nth-child(3)
  .navbar-mobile-menu {
  display: none;
}

.navbar-section .navbar-mobile .navbar-mobile-item .navbar-mobile-menu a {
  margin: 0 1rem;
  color: white;
  text-decoration: none;
  overflow: hidden;
}

.navbar-section .navbar-mobile .navbar-mobile-item:first-child,
.navbar-section .navbar-mobile .navbar-mobile-item:nth-child(2) {
  z-index: 20;
}

.navbar-section
  .navbar-mobile
  .navbar-mobile-item:nth-child(3)
  .navbar-mobile-menu
  a:hover {
  color: rgb(255, 0, 214) !important;
}

.navbar-section
  .navbar-mobile
  .navbar-mobile-item:nth-child(3)
  .hamburger-react {
  z-index: 20;
}

.navbar-section .navbar-mobile .navbar-mobile-item:nth-child(3) a:last-child {
  background-color: rgb(97, 47, 171, 0.3);
  padding: 10px 20px;
  border-radius: 100px;
  border: 0;
  margin: 20px 0 0 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: box-shadow 0.1s;
  width: 250px;
}

.navbar-section
  .navbar-mobile
  .navbar-mobile-item:nth-child(3)
  a:last-child:hover {
  box-shadow: 0px 0px 37px rgb(255, 0, 214, 0.53);
  color: white !important;
  cursor: pointer;
}

@media only screen and (max-width: 576px) {
  .navbar-mobile-item {
    width: 33% !important;
  }
}

/* upto 992px display mobile-navbar */
@media only screen and (max-width: 992px) {
  .navbar-pc {
    display: none !important;
  }
  .navbar-mobile {
    display: flex !important;
  }
  .navbar-section
    .navbar-mobile
    .navbar-mobile-item:nth-child(3)
    .navbar-mobile-menu {
    position: fixed;
    top: -100vh;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    /* background-color: rgb(255, 255, 255); */
    background-image: url("../../Resources/Theme/BG.png");
    /* opacity: 0.95; */
    /* transition: 0.8s; */
    z-index: 10;
  }
  .navbar-section
    .navbar-mobile
    .navbar-mobile-item:nth-child(3)
    .responsive-nav {
    transform: translateY(100vh) !important;
    overflow: hidden;
  }
  .navbar-section .navbar-mobile .navbar-mobile-item .navbar-mobile-menu a {
    font-size: 1.5rem;
    overflow: hidden;
  }
}

/* 992px and above display pc-navbar */
@media only screen and (min-width: 992px) {
  .navbar-pc {
    display: flex !important;
  }
  .navbar-mobile {
    display: none !important;
  }
}

.sovaka-logo {
  width: 2.5em;
  margin-right: 5px;
}
