.contact-section .contact-heading h1,
h6 {
  text-align: center;
}

.contact-section .contact-heading h1 strong span {
  background: #8f00ff;
  background: -webkit-linear-gradient(to right, #8f00ff 0%, #ff00d6 100%);
  background: -moz-linear-gradient(to right, #8f00ff 0%, #ff00d6 100%);
  background: linear-gradient(to right, #8f00ff 0%, #ff00d6 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.contact-section .contact-heading {
  margin: 50px 0;
}

.connect-input {
  display: flex;
  justify-content: center;
  align-items: center;
}

.connect-input form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.connect-input form input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 10px;
  border: none;
  outline: none;
  background-color: rgba(255, 255, 255, 0.1);
  /* color: black !important; */
  color: white !important;
}

.connect-input form label {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  width: 100%;
}

.connect-input form label:last-child {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  color: white !important;
}

.connect-input form label textarea {
  resize: none;
  appearance: none; /* arrows disabled */
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 10px;
  border: none;
  outline: none;
  background-color: rgba(255, 255, 255, 0.1);
  /* color: black !important; */
  color: white !important;
}

/* Chrome, Safari, Edge, Opera */
/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */

/* Firefox */
/* input[type=number] {
  -moz-appearance: textfield;
} */
label {
  color: white;
}
.contact-heading {
  color: white;
}

.form-submit-btn {
  background-color: #301854 !important;
  padding: 10px 20px !important;
  border-radius: 100px !important;
  border: 0 !important;
  margin: 11px 0 0 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  transition: box-shadow 0.1s !important;
}
.form-submit-btn:hover {
  box-shadow: 0px 0px 17px rgb(255, 0, 214, 0.53);
  cursor: pointer;
}
